import {ExclamationTriangleIcon} from '@heroicons/react/20/solid'

export default function getTodayPatients() {
    return {
        patients: [
            {
                id: '1',
                name: 'Thomas Schiet',
                sex: '♂',
                dob: '31-02-2000',
                time: '10:00 → 10:20',
                duration: '20 min',
                status: 'planned',
                appointmenttype: 'CO',
                diagnosis: 'Putatus biceps majoris',
                img: '/',
                icon: '',
                colorClass: '',
            },
            {
                id: '2',
                name: 'Maja van Dijk',
                sex: '♀',
                dob: '12-07-1989',
                time: '10:20 → 10:40',
                duration: '20 min',
                status: 'arrived',
                appointmenttype: 'CO',
                diagnosis: 'Gindervitus',
                img: '/',
                icon: ExclamationTriangleIcon,
                colorClass: 'text-amber-500',
            },
            {
                id: '3',
                name: 'Arjen Peijen',
                sex: '♂',
                dob: '13-09-1992',
                time: '10:40 → 11:00',
                duration: '20 min',
                status: 'in_progress',
                appointmenttype: 'CO',
                diagnosis: 'Intoxicatio alcoholica',
                img: '/',
                icon: '',
                colorClass: '',
            },
            {
                id: '4',
                name: 'Tim de Groot',
                sex: '♂',
                dob: '28-03-1997',
                time: '11:00 → 12:00',
                duration: '60 min',
                status: 'planned',
                appointmenttype: 'NP',
                diagnosis: 'Shin splints',
                img: '/',
                icon: '',
                colorClass: '',
            },
            {
                id: '5',
                name: 'Robin Geuze',
                sex: '♂',
                dob: '18-05-1990',
                time: '13:00 → 13:20',
                duration: '20 min',
                status: 'planned',
                appointmenttype: 'CO',
                diagnosis: 'Adipositas abdominalis minor',
                img: '/',
                icon: '',
                colorClass: '',
            },
            {
                id: '6',
                name: 'Eva van der Kroft',
                sex: '♀',
                dob: '05-01-2003',
                time: '13:20 → 13:40',
                duration: '20 min',
                status: 'planned',
                appointmenttype: 'CO',
                diagnosis: 'Ludomania chronica',
                img: '/',
                icon: '',
                colorClass: '',
            },
            {
                id: '7',
                name: 'Bram Ekelschot',
                sex: '♂',
                dob: '16-12-2001',
                time: '14:00 → 14:20',
                duration: '20 min',
                status: 'planned',
                appointmenttype: 'CO',
                diagnosis: 'Gaudium chronicum',
                img: '/',
                icon: '',
                colorClass: '',
            },
            {
                id: '8',
                name: 'Jan Ferdinand Henseler',
                sex: '♂',
                dob: '07-03-1987',
                time: '14:20 → 14:40',
                duration: '20 min',
                status: 'planned',
                appointmenttype: 'CO',
                diagnosis: 'Cerebralis superstimulus',
                img: '/',
                icon: '',
                colorClass: '',
            },
        ],
    } as const
}
