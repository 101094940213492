'use client'
import classNames from 'classnames'
import {CSSProperties, useCallback, useEffect, useRef, useState} from 'react'
import {useInView} from 'react-intersection-observer'

import {ClassProps, Line} from '../../types/types'
import getRandomNumber from '../RandomNumber'

export default function HeroLines({children}: ClassProps) {
    const {ref, inView} = useInView({threshold: 0.4, triggerOnce: true})
    const [animatedLines, setAnimatedLines] = useState<Line[]>([])
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
    const removeLine = useCallback(
        (id: string) => (_e: React.AnimationEvent<HTMLSpanElement>) => {
            setAnimatedLines((prev) => prev.filter((line) => line.id !== id))
        },
        [],
    )
    useEffect(() => {
        const renderLine = (timeout: number) => {
            timeoutRef.current = setTimeout(() => {
                setAnimatedLines((lines) => [
                    ...lines,
                    {
                        direction: Math.random() > 0.5 ? 'to bottom' : 'to right',
                        duration: getRandomNumber(1500, 2200),
                        size: getRandomNumber(5, 15),
                        id: Math.random().toString(36).substring(7),
                    },
                ])
                renderLine(getRandomNumber(1500, 2500))
            }, timeout)
        }
        renderLine(getRandomNumber(250, 800))
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
        }
    }, [setAnimatedLines])
    return (
        <section ref={ref} className="block w-full h-min [perspective:2000px] mt-32">
            <div
                className={classNames(
                    'relative grid grid-rows-1 grid-cols-1 w-full h-full pointer-events-none select-none hero-gradient-light bg-white bg-opacity-[1] rounded-lg ring-1 ring-blue-500/10 ring-inset',
                    inView ? 'animate-image-rotate' : '[transform:rotateX(25deg)]',
                    'before:absolute before:w-full before:h-full before:top-0 before:left-0 before:[filter:blur(125px)] before:hero-glow-gradient-light before:opacity-20',
                    inView && 'before:animate-image-glow',
                )}
            >
                <div className="absolute top-0 left-0 h-full w-full z-20 overflow-hidden">
                    {animatedLines.map((line) => (
                        <span
                            key={line.id}
                            onAnimationEnd={removeLine(line.id)}
                            style={
                                {
                                    '--direction': line.direction,
                                    '--size': line.size,
                                    '--animation-duration': `${line.duration}ms`,
                                } as CSSProperties
                            }
                            className={classNames(
                                'container block absolute top-0 hero-glow-lines-light overflow-hidden',
                                line.direction === 'to right' &&
                                    `left-0 w-[calc(var(--size)*1rem)] h-[1px] animate-glow-line-horizontal mx-2`,
                                line.direction === 'to bottom' &&
                                    `right-0 h-[calc(var(--size)*1rem)] w-[1px] animate-glow-line-vertical my-2`,
                            )}
                        />
                    ))}
                </div>
                <div className="absolute top-0 left-0 aspect-video w-full flex flex-row rounded-lg">
                    <div className="w-48 h-full border-r border-blue-500/10" />
                    <div className="w-full h-10 border-b border-blue-500/10" />
                </div>
                <div
                    className={classNames(
                        'relative top-0 left-0 aspect-video w-full flex flex-row transition-opacity [transition-delay:680ms] rounded-lg border border-blue-500/15',
                        inView
                            ? 'opacity-95 bg-white pointer-events-auto [filter:saturate(120%)]'
                            : 'opacity-0',
                    )}
                >
                    {children}
                </div>
            </div>
        </section>
    )
}
